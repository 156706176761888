<template>
  <div ref="highchart-container" style="height: 100%; width: 100%">
    <highcharts v-if="seriesData && seriesData.length && ready" ref="chart" :options="chartOptions" style="width: 100%;"></highcharts>
  </div>
</template>

<script>
import {Chart as highcharts} from "highcharts-vue";
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'VQBCellWidgetTemplate',
  mixins: [WidgetViewMixin],
  components: {highcharts},
  props: {
    result: {type: Array},
    data: {type: Object},
  },
  mounted() {
    this.ready = true; // Почему-то при ресайзе ref теряется
  },
  computed: {
    chartOptions() {
      let options = {
        title: {
          text: null,
          // align: 'center',
          // verticalAlign: 'middle',
          // y: -60,
          // style: {
          //   fontSize: '1.1em'
          // }
        },
        colors: this.data.colors.map(color => color.value),
        credits: {enabled: false},
        chart: {
          type: 'pie',
          height: this.$refs["highchart-container"].clientHeight
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: this.data.labelsX || false,
              distance: this.data.labelDistance || 20,
              format: '{point.name}: {point.y}'
              // format: '{point.name}: {point.percentage:.1f}%'
            },
            innerSize: this.data.innerSize ? this.data.innerSize+'%' : '0%',
            showInLegend: true,
          }
        },
        tooltip: {
          enabled: this.data.tooltips || false,
          pointFormat: 'Значение: {point.y}<br>В процентах: {point.percentage:.1f}%'
        },
        legend: {enabled: this.data.legend || false},
        series: [{
          colorByPoint: true,
          data: this.seriesData
        }]
      };

      if( this.data.halfCircle ){
        options.plotOptions.pie = {
          ...options.plotOptions.pie,
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      }

      return options;
    },

    seriesData(){
      if( !this.result || !this.result.length ){
        return [];
      }
      let data = [];
      this.result[0].result.data.forEach(row => {
        data.push({
          name: row[this.result[0].result.headers[0].column],
          y: parseFloat(row[this.result[0].result.headers[1].column])
        });
      })
      return data;
    }
  },
  data(){
    return {
      ready: false,
    }
  },
  methods: {}
}
</script>